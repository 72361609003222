<template>
  <div :class="['card', { 'border-0' : noBorder }]">
    <div class="card-body">
      <div class="row mb-3 list-header-block">
        <h2 class="card-title col">{{ title }}</h2>

        <div class="dropdown col-auto" v-if="filtered">
          <a class="nav-link dropdown-toggle border rounded border-primary" href="#" id="navbarDropdownTownSelect" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
            <span>Sort by</span>
          </a>
          <div class="dropdown-menu pl-2" aria-labelledby="navbarDropdownTownSelect">
            <label class="control control-checkbox dropdown-item" v-for="filter in filterGroups" :key="filter.code" @click.stop>
              {{ filter.display }}
              <input type="checkbox" :name="uniqueKey + '-filter'" :value="filter.code" :checked="filter.active" @change="onFilterSelected($event.target.value)" />
              <div class="control_indicator"></div>
            </label>
          </div>
        </div>
      </div>

      <ul class="list-group list-group-flush border-bottom">
        <transition-group name="fade" mode="out-in">
          <li class="list-group-item d-flex px-2" v-for="(record, index) in records" :key="'dashboard-list-item-' + index">
            <slot :record="record"></slot>
          </li>
        </transition-group>
      </ul>

      <div class="alert alert-warning mt-2" v-if="recordsTotalCount === 0">
        <slot name="no-records">
          No records available...
        </slot>
      </div>

      <p class="text-center mb-0 mt-3" v-if="displayRecordsCount < recordsTotalCount">
        <button type="button" class="btn btn-link" @click="insertAdditionalRecords">Load More</button>
      </p>
    </div>
  </div>
</template>

<style scoped>
.list-header-block {
  height: 42px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  animation: fadein 1s;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  animation: fadein 1s reverse;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

<script>
export default {
  name: "dashboard-list",
  props: {
    title: { type: String, required: true },
    records: { type: Array, default: () => [] },
    total: { type: Number, default: 0 },
    filtered: { type: Boolean, default: false },
    filterGroups: { type: Array },
    filterStartAs: { type: String },
    noBorder: { type: Boolean, default: false },
    increments: { type: Number, default: 3 },
    displayAll: { type: Boolean, default: false }
  },
  computed: {
    displayRecordsCount () {
      return this.records.length;
    },
    uniqueKey() {
      return this.title.toLowerCase().replace(" ", "-");
    },
    recordsTotalCount() {
      return this.total || this.records.length;
    }
  },
  methods: {
    onFilterSelected: function(e) {
      this.$emit("filter-changed", e);
    },
    async insertAdditionalRecords() {
      this.$emit("fetch", this.displayRecordsCount + this.increments);
    },
  },
};
</script>
